import React, { Fragment } from 'react'
import { GatsbyImage } from "gatsby-plugin-image"
import { MDBCol, MDBCard, MDBCardBody, MDBCardText } from 'mdbreact'
import CustomLink from './CustomLink.js'

class Card extends React.Component {
  render() {
    const colHeight = this.props.height != null ? this.props.height : "auto"

    return (
      <MDBCol lg={this.props.collg} md={this.props.colmd} className="pb-card d-flex" >
        <MDBCard className="flex-fill">
          <CustomLink link={this.props.link}>
            <GatsbyImage image={this.props.image} alt={this.props.alt} className="rounded-top drop-shadow" />
          </CustomLink>
          <MDBCardBody>
            <div style={{maxHeight: colHeight, minHeight: colHeight}} > 
              <h3 className={`font-alt font-w-700 mt-2 letter-spacing-1 ` + (this.props.titleclass ? this.props.titleclass : `title-small title-xs-medium`)}>
                {this.props.title}
              </h3>
              {this.props.subtitle && (
                <div className={this.props.subclass ? "font-w-400 text-xs-medium text-medium-small mt-2" : "font-w-400 text-xs-medium text-medium mt-2"} 
                  dangerouslySetInnerHTML={{ __html: this.props.subtitle, }} />
              )}
            </div>
            <hr />
            {this.props.docs &&
              <MDBCardText className="font-w-400 text-card-small richtext divlink">
                  {this.props.docs.map((docs, index) => (
                    <Fragment key={index}>
                      {docs.document && (
                        <>
                          {index > 0 ? 
                            <>&nbsp;&nbsp;|&nbsp;&nbsp;<a href={docs.document.publicURL} className="effect" target="_blank" rel="noopener"> {docs.description} </a></>
                          : 
                            <a href={docs.document.publicURL} className="effect" target="_blank" rel="noopener" > {docs.description} </a>
                          }
                        </>
                      )}
                    </Fragment>
                ))}
              </MDBCardText>
            }
            {!this.props.docs && 
              <>
                {this.props.link ? (
                  <MDBCardText className="font-w-400 text-card-small richtext divlink">
                    <CustomLink link={this.props.link} className="effect">
                      {this.props.description}
                    </CustomLink>
                  </MDBCardText>
                ) : (
                  <MDBCardText className="font-w-400 text-medium mt-2 mb-2"></MDBCardText>
                )}
              </>
            }
          </MDBCardBody>
        </MDBCard>
      </MDBCol>
    )
  }
}
export default Card