import React from 'react'
import { MDBRow, MDBCol } from 'mdbreact'
import { GatsbyImage } from "gatsby-plugin-image"
import CustomLink from './CustomLink'

class ColumnAlternating extends React.Component {
  render() {
    const Heading = (this.props.headingLevel && Number.isInteger(this.props.headingLevel)) ? `h${this.props.headingLevel}` : 'h3'
    const SubHeading = (this.props.headingLevel && Number.isInteger(this.props.headingLevel)) ? `h${this.props.headingLevel+1}` : 'h4'
    return (
      <MDBRow className={`about-item align-items-center ${ this.props.placement === 'right' ? 'flex-row-reverse' : '' }`} >
        <MDBCol lg={this.props.imageCol ? this.props.imageCol : '6'} className="order-2 order-lg-1">
          <GatsbyImage
            image={this.props.image}
            alt={this.props.alt}
            className="rounded"
          />
        </MDBCol>
        <MDBCol lg={this.props.textCol ? this.props.textCol : '6'} className="mt-4 mt-lg-0 mb-4 mb-lg-0 order-1 order-lg-2">
          <Heading className={ this.props.titleClass != null ? this.props.titleClass : 'font-alt font-w-700 letter-spacing-1 mb-3 title-xs-medium title-medium text-very-dark-blue' } >
            {this.props.title}
          </Heading>

          {this.props.subtitle != null && (
            <SubHeading className={ this.props.subtitleClass != null ? this.props.subtitleClass : 'font-alt font-w-700 letter-spacing-1 mb-4 title-xs-medium title-medium' } >
              {this.props.subtitle}
            </SubHeading>
          )}

          <div className="mt-3 font-w-400 text-medium" style={{ whiteSpace: 'pre-wrap' }} dangerouslySetInnerHTML={{ __html: this.props.description }} />
          {this.props.link && 
            <p className="pt-3">
              <CustomLink link={this.props.link} className="mt-4 effect" aria-label={this.props.title}>
                {this.props.linktext}
              </CustomLink>
            </p>
          }
        </MDBCol>
      </MDBRow>
    )
  }
}
export default ColumnAlternating